import React, { useState, Suspense, useRef, useEffect } from "react";
import { Box, Button, Image, Text, Stack, Overlay, Tabs } from "@mantine/core";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import type { OrbitControls as OrbitControlsType } from "three-stdlib";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import { useMediaQuery } from "@mantine/hooks";
import { PerspectiveCamera, Vector3 } from "three";
import { ModelRenderer } from "./ModelRenderer";
import { getLanguageEnumByKeyForAnatomyModel } from "../../../assets/LanguageEnums/AnatomyEnumFunction";
import { getSimulationById } from "../../../features/Simulations/getSimulationSlice";

const anno1 =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-26-01-194Z.png";
const anno2 =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-26-20-082Z.png";
const anno3 =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-26-38-496Z.png";
const anno4 =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-27-03-358Z.png";
const anno5 =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-27-21-363Z.png";
const Play =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-27-44-682Z.png";
const Pause =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-28-03-310Z.png";
const closeImg =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-30T09-28-22-597Z.png";
const AccountIcon =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-27T10-51-25-879Z.png";
const zoominIcon =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-19T07-59-51-504Z.png";
const zoomoutIcon =
  "https://vignam-content-images.s3.ap-south-1.amazonaws.com/2024-07-19T08-00-06-254Z.png";
const awsModellink = "https://vignammodelsstage.s3.ap-south-1.amazonaws.com/";
const menuItems = [
  { id: 1, text: "Draw_Tool", img: anno1 },
  { id: 2, text: "Annotations", img: anno2 },
  { id: 3, text: "Rotate", img: anno3 },
  { id: 4, text: "Pan", img: anno4 },
  { id: 5, text: "Reset", img: anno5 },
  { id: 6, text: "Zoom_in", img: zoominIcon },
  { id: 7, text: "Zoom_out", img: zoomoutIcon },
];

const AnatomyModelSimulation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [SimulationData, setSimulationData] = useState<any>([]);
  const [hovered, setHovered] = useState<number | null>(null);
  const [pan, setPan] = useState<boolean>(false);
  const [rotate, setRotate] = useState<boolean>(true);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const controlsRef = useRef<OrbitControlsType>(null);
  const cameraRef = useRef<PerspectiveCamera>();
  const [drawingMode, setDrawingMode] = useState(false);
  const drawCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastPosition, setLastPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [annotations, setAnnotations] = useState<boolean>(true);
  const [isDetailModel, setisDetailModel] = useState<boolean>(false);
  const [cleanedName, setCleanedName] = useState<string>("");
  const [imageColors, setImageColors] = useState<{ [key: number]: string }>({
    1: "black",
    2: "black",
    3: "white",
    4: "black",
    5: "black",
  });
  const [modelStage, setModelStage] = useState<boolean | null>(null);
  const [clickedPart, setClickedPart] = useState<string>("");
  const [isAnimation, setIsAnimation] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isAccountOpen, setAccountOpen] = useState<boolean>(false);
  const [userLanguage, setUserLanguage] = useState<string>("en");
  const path = window.location.pathname;
  const currentPath = window.location.pathname;
  const segments = currentPath.split("/").filter(Boolean);
  const lastSegment = segments[segments.length - 1];
  const language = location.state && location.state.language;


  useEffect(() => {
    getSimulationById({id:lastSegment})
      .then((simulation: any) => {
        setSimulationData(simulation.data);
      })
      .catch((err) => {
        console.log(err);
      });
    if (language) {
      setUserLanguage(language);
    }
  }, []);

  useEffect(() => {
    if (SimulationData.length > 1) {
      setModelStage(true);
      setisDetailModel(false);
    } else if (SimulationData.length == 1) {
      setModelStage(false);
      setisDetailModel(true);
    }
  });

  const handleAnnotationClick = (name: string) => {
    setCleanedName(name);
  };

  var click = false;
  const handleMouseClick = (model: any) => {
    if (!click) {
      click = true;
      const newPath = path + "/" + model.ModelName;
      navigate(`${newPath}`, {
        state: { model, userLanguage, SimulationData },
      });
    }
  };

  const handlePartClick = (name: any) => {
    const model = SimulationData[0];
    let clicked = false;
    model.object_reference.find((object: any) => {
      if (object.label === name && !clicked) {
        clicked = true;
        setClickedPart(object.ref);
        handleMouseClick(object);
      }
    });
  };

  useEffect(() => {
    const canvas = drawCanvasRef.current;
    if (canvas) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }
  }, []);

  useEffect(() => {
    const handleWheel = (event: any) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    const canvas = document.querySelector("canvas");
    if (canvas) {
      canvas.addEventListener("wheel", handleWheel);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  const handleMouseDown = (event: React.MouseEvent) => {
    if (drawingMode) {
      setIsDrawing(true);
      setLastPosition({ x: event.clientX, y: event.clientY });
    }
  };

  const handleMouseUp = () => {
    if (drawingMode) {
      setIsDrawing(false);
      setLastPosition(null);
    }
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (isDrawing && drawingMode && lastPosition) {
      const canvas = drawCanvasRef.current;
      const context = canvas?.getContext("2d");
      if (context) {
        context.strokeStyle = "black";
        context.lineWidth = 2;
        context.beginPath();
        context.moveTo(lastPosition.x, lastPosition.y);
        context.lineTo(event.clientX, event.clientY);
        context.stroke();
        setLastPosition({ x: event.clientX, y: event.clientY });
      }
    }
  };

  const handleMenuClick = (key: number) => {
    if (key === 1) {
      setDrawingMode(!drawingMode);
    } else if (key === 4) {
      setPan(!pan);
    } else if (key === 3) {
      setRotate(!rotate);
    } else if (key === 2) {
      setAnnotations(!annotations);
    } else if (key === 5) {
      setPan(false);
      setRotate(false);
      setDrawingMode(false);
      setAnnotations(true);
      const canvas = drawCanvasRef.current;
      if (canvas) {
        const context = canvas.getContext("2d");
        if (context) {
          context.clearRect(0, 0, canvas.width, canvas.height);
        }
      }
      if (controlsRef.current) {
        controlsRef.current.reset();
      }
      setImageColors({
        1: "black",
        2: "black",
        3: "black",
        4: "black",
        5: "black",
      });
      return;
    } else if (key == 6) {
      handleZoomIn();
    } else if (key == 7) {
      handleZoomOut();
    }
    setImageColors((prevColors) => ({
      ...prevColors,
      [key]: prevColors[key] === "black" ? "white" : "black",
    }));
  };

  const handleAnimationButton = () => {
    setIsPlaying(!isPlaying);
  };

  const handleAnimationAvailable = () => {
    setIsAnimation(true);
  };

  const handleZoomIn = () => {
    if (cameraRef.current) {
      cameraRef.current.zoom = Math.min(cameraRef.current.zoom + 0.1, 5);
      cameraRef.current.updateProjectionMatrix();
    }
  };

  const handleZoomOut = () => {
    if (cameraRef.current) {
      cameraRef.current.zoom = Math.max(cameraRef.current.zoom - 0.1, 0.1);
      cameraRef.current.updateProjectionMatrix();
    }
  };

  const SetCameraRef = () => {
    const { camera } = useThree();
    useEffect(() => {
      cameraRef.current = camera as PerspectiveCamera;
    }, [camera]);
    return null;
  };

  return (
    <Box
      style={{
        position: "relative",
        height: "100vh",
        width: "100vw",
        background: "linear-gradient(to right, #D9DEE8, #ffffff, #D9DEE8)",
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      {isAccountOpen && (
        <>
          <Overlay
            opacity={0.8}
            color="#000"
            zIndex={1001}
            onClick={() => setAccountOpen(false)}
          />
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 1001,
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              width: "400px",
              height: "250px",
              borderRadius: "15px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                height: "25%",
              }}
            >
              <Text sx={{ fontWeight: 700, fontSize: "25px" }}>
                {getLanguageEnumByKeyForAnatomyModel({
                  key: "Preferences",
                  LanguageId: userLanguage,
                })}
              </Text>
              <Box style={{ cursor: "pointer" }}>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => setAccountOpen(false)}
                  src={closeImg}
                  width={15}
                  height={15}
                />
              </Box>
            </Box>
            <Box sx={{ height: "70%", width: "100%" }}>
              <Tabs defaultValue="overview">
                <Tabs.List>
                  <Tabs.Tab value="overview">
                    {getLanguageEnumByKeyForAnatomyModel({
                      key: "Overview",
                      LanguageId: userLanguage,
                    })}
                  </Tabs.Tab>
                  <Tabs.Tab value="languages">
                    {getLanguageEnumByKeyForAnatomyModel({
                      key: "Languages",
                      LanguageId: userLanguage,
                    })}
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="overview" pt="lg">
                  <Text>
                    {getLanguageEnumByKeyForAnatomyModel({
                      key: "Preference_Content",
                      LanguageId: userLanguage,
                    })}
                  </Text>
                </Tabs.Panel>
                <Tabs.Panel value="languages" pt="lg">
                  <Box
                    sx={{
                      width: "100%",
                      height: "70px",
                      border: "2px solid black",
                      borderRadius: "10px",
                      padding: "7px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{
                        borderRadius: "10px",
                        height: "100%",
                        width: "45%",
                        border: "1px solid transparent",
                        backgroundColor:
                          userLanguage == "en" ? "#BACEF4" : "transparent",
                      }}
                      variant="outline"
                      onClick={() => setUserLanguage("en")}
                    >
                      <Text sx={{ fontSize: "22px" }} fw={700} c="black">
                        English
                      </Text>
                    </Button>
                    <Button
                      style={{
                        borderRadius: "10px",
                        height: "100%",
                        width: "45%",
                        border: "1px solid transparent",
                        backgroundColor:
                          userLanguage == "hi" ? "#BACEF4" : "transparent",
                      }}
                      variant="outline"
                      onClick={() => setUserLanguage("hi")}
                    >
                      <Text sx={{ fontSize: "22px" }} fw={700} c="black">
                        हिंदी
                      </Text>
                    </Button>
                  </Box>
                </Tabs.Panel>
              </Tabs>
            </Box>
          </Box>
        </>
      )}
      <Box
        sx={{
          position: "fixed",
          top: 10,
          right: 10,
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <Button
          onClick={() => setAccountOpen(true)}
          variant="outline"
          style={{
            padding: 0,
            width: 50,
            height: 50,
            border: "0.1px solid transparent",
          }}
        >
          <img
            src={AccountIcon}
            alt="AccountIcon"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Button>
      </Box>

      <ErrorBoundary>
        <Canvas
          shadows
          camera={{ position: [0, 2, 10], fov: 35 }}
          style={{ width: "100%", height: "100%" }}
        >
          <SetCameraRef />
          <Suspense fallback={null}>
            <ambientLight intensity={1} />
            <directionalLight position={[1, 5, 1]} intensity={1.5} />
            {modelStage
              ? SimulationData.map((item: any) => (
                  <ModelRenderer
                    onClick={() => handleMouseClick(item)}
                    onAnnotationClick={handleAnnotationClick}
                    key={item.id}
                    annotationValue={annotations}
                    detailModel={isDetailModel}
                    rotation={[
                      item.rotation[0],
                      item.rotation[1],
                      item.rotation[2],
                    ]}
                    position={
                      isSmallScreen
                        ? new Vector3(...item.smallScreenPosition)
                        : new Vector3(...item.position)
                    }
                    scale={
                      isSmallScreen
                        ? new Vector3(...item.smallScreenScale)
                        : new Vector3(...item.scale)
                    }
                    modelPath={awsModellink + item.path}
                    onModelClick={() => handleMouseClick(item)}
                  />
                ))
              : SimulationData.map((item: any) => (
                  <ModelRenderer
                    onAnnotationClick={handleAnnotationClick}
                    key={item.id}
                    annotationValue={annotations}
                    detailModel={isDetailModel}
                    rotation={[
                      item.rotation[0],
                      item.rotation[1],
                      item.rotation[2],
                    ]}
                    position={
                      isSmallScreen
                        ? new Vector3(...item.smallScreenPosition)
                        : [0, -1.5, 0]
                    }
                    scale={
                      isSmallScreen
                        ? new Vector3(...item.smallScreenScale)
                        : [0.4, 0.4, 0.4]
                    }
                    modelPath={awsModellink + item.path}
                    onPartClick={handlePartClick}
                    isPlaying={isPlaying}
                    onAnimation={handleAnimationAvailable}
                  />
                ))}
          </Suspense>
          <OrbitControls
            ref={controlsRef}
            enablePan={pan}
            enableRotate={rotate}
          />
        </Canvas>
      </ErrorBoundary>
      <canvas
        ref={drawCanvasRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: drawingMode ? "auto" : "none",
          display: drawingMode ? "block" : "none",
        }}
      />
      <Stack
        align="flex-end"
        justify="center"
        style={{
          right: "2%",
          top: "50%",
          transform: "translateY(-50%)",
          background: "transparent",
          position: "fixed",
        }}
      >
        {isAnimation && (
          <Button
            onClick={handleAnimationButton}
            style={{
              position: "relative",
              marginBottom: 10,
              paddingLeft: 0,
              paddingRight: 0,
              width: 50,
              height: 50,
              backgroundColor: "white",
              color: "white",
            }}
            onMouseEnter={() => setHovered(30)}
            onMouseLeave={() => setHovered(null)}
          >
            <Image
              src={isPlaying ? Pause : Play}
              alt={"animation"}
              width={28}
              height={28}
            />
            {hovered === 30 && (
              <Text
                style={{
                  position: "absolute",
                  right: "calc(100% + 20px)",
                  top: "50%",
                  transform: "translateY(-50%)",
                  whiteSpace: "nowrap",
                  backgroundColor: "black",
                  color: "white",
                  padding: "7px",
                  borderRadius: "5px",
                  fontSize: "0.8rem",
                  maxWidth: "150px",
                }}
              >
                {getLanguageEnumByKeyForAnatomyModel({
                  key: isPlaying ? "Pause" : "Play",
                  LanguageId: userLanguage,
                })}
              </Text>
            )}
          </Button>
        )}
        {menuItems.map((item) => (
          <Button
            key={item.id}
            onClick={() => handleMenuClick(item.id)}
            className="menu-item"
            style={{
              position: "relative",
              marginBottom: 10,
              paddingLeft: 0,
              paddingRight: 0,
              width: 50,
              height: 50,
              backgroundColor:
                item.id === 1 && drawingMode
                  ? "#4B65F6"
                  : item.id === 4 && pan
                  ? "#4B65F6"
                  : item.id === 3 && rotate
                  ? "#4B65F6"
                  : item.id === 2 && !annotations
                  ? "#4B65F6"
                  : hovered === item.id
                  ? "#F3F3F6"
                  : "white",
              color: item.id === 1 && drawingMode ? "white" : "black",
            }}
            onMouseEnter={() => setHovered(item.id)}
            onMouseLeave={() => setHovered(null)}
          >
            <Image
              src={item.img}
              alt={item.text}
              width={28}
              height={28}
              style={{
                filter:
                  item.id !== 5 &&
                  item.id !== 6 &&
                  item.id !== 7 &&
                  imageColors[item.id] === "white"
                    ? "invert(1)"
                    : "invert(0)",
              }}
            />
            {hovered === item.id && (
              <Text
                style={{
                  position: "absolute",
                  right: "calc(100% + 20px)",
                  top: "50%",
                  transform: "translateY(-50%)",
                  whiteSpace: "nowrap",
                  backgroundColor: "black",
                  color: "white",
                  padding: "7px",
                  borderRadius: "5px",
                  fontSize: "0.8rem",
                  maxWidth: "150px",
                }}
              >
                {getLanguageEnumByKeyForAnatomyModel({
                  key: item.text,
                  LanguageId: userLanguage,
                })}
              </Text>
            )}
          </Button>
        ))}
      </Stack>
    </Box>
  );
};

export default AnatomyModelSimulation;
